// react
import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
// external libraries
import { Redirect } from "react-router-dom";
import HomeNavbar from "./HomeNavbar";
import LoginButton from "components/LoginButton/LoginButton";
import Box from "@material-ui/core/Box";
// styling
import "./HomePage.scss";
import heroVideo from "assets/videos/home.mov";

/**
 *
 * @param {boolean} loggedIn whether user is logged in
 * @returns {component} the HomePage component
 */
const HomePage = ({ loggedIn }) => {
  /* -------------------------------------------------------------------------
    states, props and hooks
    ------------------------------------------------------------------------- */

  // If user is not logged in and pasted custom link, user will be prompted
  // to log in and redirected to /.
  // We use localStorage to redirect the user to their desired link.
  const [redirect, setRedirect] = useState({ status: false, url: "/" });
  const [mobileView, setMobileView] = useState(false);
  const [logOutFrameSrc, setLogOutFrameSrc] = useState("");

  // mobile view logic
  useEffect(() => {
    const setResponsiveness = () => {
      setMobileView(window.innerWidth < 900 ? true : false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  // redirect user to the custom URL they pasted prior to login, if applicable
  useEffect(() => {
    if (loggedIn === true) {
      // Check for redirectUrl
      const redirectUrl = window.localStorage.getItem("bimhubRedirectUrl");

      // Do not redirect if url is / or /# (e.g. /#howto on Home screen)
      // React Router can't handle that
      if (
        redirectUrl !== null &&
        redirectUrl !== "/" &&
        redirectUrl[1] !== "#"
      ) {
        // remove redirect URL in storage because only redir once
        window.localStorage.removeItem("bimhubRedirectUrl");
        setRedirect({
          status: true,
          url: redirectUrl,
        });
      }
    }

    if (loggedIn === false) {
      setLogOutFrameSrc("https://accounts.autodesk.com/Authentication/LogOut");
    }
  }, [loggedIn]);

  if (redirect.status) return <Redirect to={redirect.url} />;

  /* -------------------------------------------------------------------------
    rendering
    ------------------------------------------------------------------------- */
  return (
    <Fragment>
      {/*
                Whenever user is not logged in, load 
                https://accounts.autodesk.com/authentication/logout
                as src of the iframe.
            */}
      <iframe
        title="Forge logout frame"
        id="Autodesk-Forge-logOut-frame"
        style={{ display: "none" }}
        src={logOutFrameSrc}
      />
      <HomeNavbar loggedIn={loggedIn} mobileView={mobileView} />

      <section className="root">
        <ReactPlayer
          url={heroVideo}
          playing
          loop
          muted
          width="100%"
          height="100%"
        />
        <div className="overlay">
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="#fff"
          >
            <img height="50" src="/BIMhub.png" alt="BIM Hub logo"></img>
            <h3 style={{ color: "#ffffff", fontWeight: 100, fontSize: 16 }}>
              For access, please contact BIM Studio Inc.
            </h3>
            <LoginButton loggedIn={loggedIn} />
          </Box>
        </div>
      </section>
    </Fragment>
  );
};

HomePage.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

export default HomePage;
